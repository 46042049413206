import * as React from 'react'
import * as ReactRedux from 'react-redux'
import { v4 as generateUserId } from 'uuid'

import * as Cookies from './cookies-module'
import * as Constants from './constants'
import * as Logger from './logger'
import * as ServerConfiguration from './server-configuration'
import * as Configuration from './configuration'

/**
 * @typedef {Object} ExperimentResult
 * @property {?Object} error Network or GraphQLErrors
 * @property {boolean} fetching Fetching indicating whether the query is currently being fetched
 * @property {?string} variation Experiment variation if assigned to user, otherwise undefined
 */

/**
 * @param {Object} config
 * @param {Object} config.customVariables
 * @param {string} config.experimentId
 * @returns {Array.<ExperimentResult>}
 */
export function useExperimentVariation({ experimentId }) {
  const userId = React.useMemo(() => generateUserId(), [generateUserId])
  const [variation, setVariation] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [abUserId, setAbUserId] = Cookies.useCookie(
    Constants.CookieKeys.AB_USER_ID
  )
  const configuration = ServerConfiguration.useContext()
  const vwoEnabled = ReactRedux.useSelector(state => {
    return Configuration.isVwoEnabled(state.configuration)
  })

  if (!abUserId) {
    setAbUserId(userId, {
      maxAge: 365 * 24 * 60 * 60 * 1000,
      httpOnly: false,
      path: '/',
    })
  }

  React.useEffect(() => {
    async function fetchVariation() {
      try {
        const response = await fetch(
          `/api/ab-testing/get-variation?userId=${abUserId}&campaignKey=${experimentId}&counrtyCode=${configuration?.country?.countryCode}`
        )
        const data = await response.json()
        setVariation(data?.variation?.id)
      } catch (error) {
        setError('Error fetching VWO variation:', error)
      }
    }

    if (!variation && abUserId && vwoEnabled) {
      fetchVariation()
    }
  }, [variation, abUserId, experimentId, vwoEnabled])

  useUpdateDataLayer(experimentId, variation)

  React.useMemo(() => {
    if (error) {
      Logger.react.error(error)
    }
  }, [error])

  return {
    error: error,
    variation: variation,
  }
}

function useUpdateDataLayer(experimentId, variation) {
  React.useEffect(() => {
    const __PROD__ = process.env.NODE_ENV === 'production'

    if (__PROD__) {
      if (variation) {
        window.dataLayer.push({
          CampaignName: experimentId,
          CampaignId: experimentId,
          VariationName: variation,
          VariationId: variation,
          event: 'VWO',
        })
      }
    }
  }, [experimentId, variation])
}
