import * as React from 'react'

import { lazy as loadable } from '@loadable/component'

import { createExperimentComponent } from '../create-experiment-component'

export function PaymentProvidersSection() {
  const PaymentProvidersSectionExperiment = createExperimentComponent(
    'RegistrationPaymentProviders',
    {
      experimentId: 'Reg flow - payment logos',
      variations: {
        control: null,
        'variation-1': loadable(() => import('./variation-1')),
      },
    }
  )

  return <PaymentProvidersSectionExperiment />
}
