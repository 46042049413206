import * as R from 'ramda'
import React from 'react'

import * as Experiments from './experiments'
import * as Utils from './utils'

/**
 * React Component
 * @typedef {Function} ReactComponent
 */

/**
 * @param {Object} config
 * @param {Object} [config.customVariables] Custom Variables
 * @param {string>} config.experimentId Experiment id
 * @param {Object.<string, ReactComponent>} config.variations Variations components mappings
 * @returns {ReactComponent}
 * @example
 * import { lazy as loadable } from '@loadable/component'
 *
 * const SignUpExperiment = createExperimentComponent('SignUp', {
 *   experimentId: 'Sign-Up'
 *   variations: {
 *     control: loadable(() => import('./regily')),
 *     'variation-1': loadable(() => import('./inhouse')),
 *   },
 * })
 */
export function createExperimentComponent(name, config) {
  if (R.not(R.has('control', config.variations))) {
    throw new Error('Control variation must be defined')
  }

  const experiment = Experiments.useExperimentVariation({
    experimentId: config.experimentId,
  })

  // TODO: If needed render nothing if '<response>.fetching' is true.
  // This TODO need some battle-testing before doing something as it
  // might be redundant as useQuery suspends the subtree, so in
  // theory we never get '<response>.fetching' set to true as subtree
  // is not rendered at this moment. If prooven that it works fine
  // without handling the TODO, then remove this TODO.

  const variation = R.has(experiment.variation, config.variations)
    ? experiment.variation
    : 'control'

  const Switchable = Utils.Components.createSwitchableComponent(
    'variation',
    config.variations
  )

  function ExperimentComponent(props) {
    return <Switchable {...props} variation={variation} />
  }

  ExperimentComponent.displayName = `Experiment(${name})`

  return ExperimentComponent
}
